$white: white;
$black: #000;
$dark-grey: #454545;
$orange: #d56f4f;
$purple: #945197;
$cream: #fdf3d6;
$yellow: #f9ce5b;
$red: #f06564;
$blue: #9ddbf6;
$dark-blue: #0055a5;
$mid-blue: #6d8fbe;
$sky-blue: #bee5fb;
$rose: #fff5ff;
$pink: #cf2f70;
$light-pink: #f0c3d8;
$green: #3d9059;

//activities
$activities-bg: #faebf2;

$max-width: 1060px;
$animation-time: 0.3s;
$section-border-top: $red;
$background-body: $white;
$body-font-color: $dark-grey;
$primary-color: $pink;
$secondary-color: $pink;
$background-buy-section: $yellow;
$button-link-hover: #b71e5c;

$assetBackgrounds: $pink, $purple, #b13267, #f257a5, #b847af;

/* Header */
$header-background-color: $light-pink;

/* Video */
$background-video: #dc6998;

/* NAVIGATION */
$nav-links-colour: $white;
$nav-links-colour-hover: $white;
$nav-links-background-colour-hover: $button-link-hover;
$nav-current-link-colour: $white;
$nav-current-link-background-colour: $button-link-hover;
$nav-background-colour: $pink;

/* default font sizes */

$base-font-size: 24px;
$h1-font-size: 60px;
$h2-font-size: 60px;
$h3-font-size: 24px;
$h4-font-size: 18px;
$h5-font-size: 16px;

//breakpoints
$m: 1023px;
$s: 767px;
