.books {
    .component__text {
        h1 {
            margin-bottom: 16px;
        }
        &.grid-subtitle {
            margin: 0 auto;
            max-width: 600px;
        }
    }
    .book-landing {
        .pagination-top,
        .pagination-bottom {
            .pagination {
                li {
                    margin-bottom: 16px;
                }
            }
        }
        .book-card {
            @media (max-width: $s) {
                padding: 10px 4px;
            }
            ul {
                min-height: 110px;
                @media (max-width: $s) {
                    min-height: 90px;
                }
            }
            .book-title {
                padding: 16px 0 8px;
                a {
                    color: $pink;
                }
            }
            .book-price {
                font-size: 22px;
            }
        }
    }
}
