.header {
  background-color: $header-background-color;
  &__logo {
    max-width: none;
    padding-top: 0 !important;
    a[href="https://www.harpercollins.com/childrens/"]
    {
      right: 7%;
      @media (max-width: $s) {
        right: 0;
      }
    }
    &__kids-image {
      width: 65px;
    }
  }
}
