.home {
  .main-hero {
    h2 {
      margin-bottom: 16px;
      font-size: 42px;
      line-height: 36px;
      text-transform: none;
      @media (min-width: $s) {
        font-size: 70px;
        line-height: 52px;
      }
    }
    .hero {
      &__container {
        &--left {
          @media (min-width: 768px) and (max-width: 1023px) {
            &.md\:w-2\/5 {
              width: 50%;
            }
          }
        }
        &--right {
          @media (min-width: 768px) and (max-width: 1023px) {
            &.md\:w-3\/5 {
              width: 50%;
            }
          }
        }
      }
    }
  }
  .secondary-hero {
    .hero {
      h2 {
        @extend %primary-font;
        margin-bottom: 64px;
        color: $pink;
        margin: 32px 0;
        font-size: $base-font-size;
        line-height: 32px;
        @media (min-width: $m) {
          margin: 16px 0 64px;
        }
      }
      &__container {
        align-items: flex-start;
        &--left {
          order: 2;
          @media (min-width: 768px) {
            &.md\:w-2\/5 {
              width: 50%;
            }
          }
        }
        &--right {
          order: 1;
          @media (min-width: 768px) {
            &.md\:w-3\/5 {
              width: 50%;
            }
          }
        }
      }
    }
    .component__link {
      display: none;
    }
  }

  .home-carousel {
    @media (min-width: 900px) and (max-width: 1280px) {
      max-width: 95%;
    }
  }

  .home-blockquote {
    .site-carousel {
      align-items: center;
    }
  }
}
