.activity-detail {
    .asset-detail {
        align-items: flex-start;
        &__image {
            img {
                max-width: 90%;
                margin: 0 auto;
                @media (max-width: 767px) {
                    max-width: 75%;
                }
            }
        }
        &__info {
            justify-content: flex-start;
        }
        &__category {
            color: $purple;
            margin: 8px 0 15px;
            @extend %primary-font;
            @media (max-width: $s) {
                text-align: center;
            }
        }
        &__title {
            color: $pink;
            text-transform: capitalize;
            margin-bottom: 8px;
            font-size: $h2-font-size;
            line-height: 54px;
            @extend %primary-font;
            @media (max-width: $s) {
                text-align: center;
                font-size: 46px;
                line-height: 40px;
            }
        }
        &__description {
            margin-bottom: 64px;
            @media (max-width: $s) {
                text-align: center;
                padding: 0 16px;
                margin-bottom: 32px;
            }
        }
        &__action {
            @include siteButton($pink, $white);
            font-size: 24px;
            text-transform: uppercase;
            padding: 20px 32px 18px 32px;
            &:hover {
                background: $white;
                color: $pink;
            }
            @media (max-width: 767px) {
                display: flex;
                width: 50%;
                justify-content: center;
                margin: 0 auto;
            }
        }
        &__carousel {
            @include full_width_colour($white);
            &:after {
                z-index: 1;
            }
        }
        &__related-title {
            color: $pink;
            @extend %secondary-font;
            text-transform: capitalize;
            font-size: 60px;
            line-height: 54px;
            padding: 24px 0 32px;
            @media (max-width: $s) {
                margin: 0;
                padding: 24px 0;
                font-size: 50px;
                line-height: 44px;
            }
        }
        &__related-subtitle {
            display: none;
        }
        .related-book {
            @media (max-width: $s) {
                flex-direction: column;
                justify-content: center;
            }
            h4 {
                color: $pink;
                @extend %primary-font;
                text-transform: none;
                font-size: 40px;
                margin-bottom: 0;
                line-height: 44px;
                @media (max-width: $s) {
                    flex-direction: column;
                    justify-content: center;
                    font-size: 32px;
                }
            }
            &__price {
                color: $pink;
                margin-top: 10px;
                font-weight: bold;
            }
            &__content {
                @media (max-width: $s) {
                    width: 100%;
                    padding: 16px;
                    text-align: center;
                    margin-top: 16px;
                }
            }
        }
    }
    .assets-carousel {
        &__book-title {
            color: $pink;
        }
    }
}
