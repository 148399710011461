.activities {
    .container {
        @media (max-width: 1279px) {
            max-width: none !important;
        }
    }
    &__title,
    &__subtitle {
        background-color: $rose;
        @include full_width_colour($rose);
        h1,
        p {
            margin-bottom: 0;
        }
    }
    &__title {
        padding-bottom: 16px;
    }
    .assets-filters {
        background-color: $rose;
        @include full_width_colour($rose);
        margin-bottom: 0;
        padding-bottom: 64px;
        @media (max-width: $s) {
            padding-left: 0;
            padding-right: 0;
        }
        .list-group-item {
            text-align: center;
            aspect-ratio: 1 / 1;
            padding: 16px;
            @media (min-width: 900px) and (max-width: 1279px) {
                padding: 16px 8px;
            }
            @for $i from 1 through length($assetBackgrounds) {
                &:nth-child(#{length($assetBackgrounds)}n + #{$i}) {
                    a {
                        background-color: nth($assetBackgrounds, $i);
                        color: $white;
                        &:hover,
                        &.is-active {
                            &:after {
                                top: -10px;
                                left: -10px;
                                bottom: -10px;
                                right: -10px;
                                background-color: $white;
                            }
                        }
                    }
                }
            }
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                border-radius: 50%;
                width: 100%;
                margin: 0 auto;
                font-size: 24px;
                position: relative;
                @media (max-width: 480px) {
                    font-size: 20px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: -1;
                    border-radius: 50%;
                    transition: all 0.3s;
                }
            }
        }
    }
    .pagination-top {
        margin: 32px 0 16px;
        .pagination {
            margin-left: auto;
            &__results-qty {
                display: none;
            }
        }
    }
    .pagination-bottom {
        margin-bottom: 0;
    }
    .pagination {
        li {
            margin-bottom: 16px;
        }
    }
    .assets-list {
        @media (min-width: 1024px) {
            grid-template-columns: repeat(5, 1fr);
        }
        .book-card {
            .book-title {
                padding: 8px;
                a {
                    color: $pink;
                    text-transform: none;
                    line-height: 27px;
                }
            }
        }
    }
}
