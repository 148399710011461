@font-face {
    font-family: "WendyLPBold";
    src: url("../fonts/35A014_0_0.eot");
    src: url("../fonts/35A014_0_0.eot?#iefix") format("embedded-opentype"),
        url("../fonts/35A014_0_0.woff2") format("woff2"), url("../fonts/35A014_0_0.woff") format("woff"),
        url("../fonts/35A014_0_0.ttf") format("truetype");
}

@font-face {
    font-family: "HooliganJF";
    src: url("../fonts/35A014_2_0.eot");
    src: url("../fonts/35A014_2_0.eot?#iefix") format("embedded-opentype"),
        url("../fonts/35A014_2_0.woff2") format("woff2"), url("../fonts/35A014_2_0.woff") format("woff"),
        url("../fonts/35A014_2_0.ttf") format("truetype");
}

%primary-font {
    font-family: "HooliganJF", sans-serif;
    font-weight: normal;
}

%secondary-font {
    font-family: "WendyLPBold";
}

%open-sans {
    font-family: "Open Sans";
    font-weight: 400;
}

.main-title,
.main-title * {
    @extend %secondary-font;
    font-size: 60px;
    line-height: 52px;
}

.subtitle,
.subtitle * {
    font-size: 20px;
    line-height: normal;
    @media (min-width: $s) {
        font-size: 24px;
    }
}

.primary-font,
.primary-font * {
    @extend %primary-font;
}

.primary-text-colour {
    color: $primary-color;
}

h1,
h2,
h3,
.secondary-font,
.secondary-font * {
    @extend %secondary-font;
}

.secondary-text-colour {
    color: $secondary-color;
}

h4,
h5,
body,
.main-font {
    @extend %primary-font;
    color: $pink;
}

body {
    font-size: $base-font-size;
    line-height: 32px;
}

// text colour classes
.main-text-colour {
    color: $body-font-color;
}

.white-text-colour {
    color: $white;
}

.green-text-colour {
    color: $green;
}

.pink-text-colour {
    color: $pink;
}

.orange-text-colour {
    color: $orange;
}

.yellow-text-colour {
    color: $yellow;
}

.blue-text-colour {
    color: $blue;
}

.red-text-colour {
    color: $red;
}

.purple-text-colour {
    color: $purple;
}

.rose-text-colour {
    color: $rose;
}

h1,
h2 {
    font-size: 50px;
    line-height: 44px;
    margin-bottom: 8px;
    @media (min-width: $s) {
        font-size: $h1-font-size;
        line-height: 60px;
        margin-bottom: 16px;
    }
}

h3 {
    font-size: $h3-font-size;
    line-height: $h3-font-size;
    margin-bottom: 8px;
    margin-top: 16px;
}

h4 {
    font-size: $h4-font-size;
    line-height: $h4-font-size + 5;
    margin-bottom: 8px;
}

h5 {
    font-size: $h5-font-size;
    line-height: $h5-font-size + 5;
    margin-bottom: 8px;
}

blockquote {
    font-size: 30px;
    line-height: 48px;
    @media (max-width: 767px) {
        font-size: 18px;
        line-height: 27px;
    }
    cite {
        font-style: normal;
        text-transform: none;
        margin-top: 32px;
        display: block;
        font-size: 18px;
        line-height: 20px;
        @media (max-width: 767px) {
            font-size: 15px;
            line-height: 22px;
            margin-top: 16px;
        }
    }
}

.pagination {
    li {
        a {
            color: $pink;
        }
    }
}
.book-card ul li.book-title {
    line-height: 27px;
    font-size: 24px;
}

.separator {
    padding-top: 32px;
    border-top: 3px solid $section-border-top;
}

a {
    color: $body-font-color;
    &:hover {
        color: $secondary-color;
        cursor: pointer;
    }
}

p {
    color: $primary-color;
    margin-bottom: 8px;
}

.quiz-button {
    @extend %secondary-font;
    @include siteButton($secondary-color, $white);
}

.primary-button {
    @extend %primary-font;
    @include siteButton($primary-color, $white);
    font-size: 24px;
    &:hover {
        background-color: $orange;
        color: $white;
    }
}

.format-button.primary-button {
    border: 2px solid $primary-color;
    color: $primary-color;
    &.active {
        color: $white;
    }
    &:hover,
    &.active {
        background-color: $primary-color;
        &:after {
            border-top-color: $primary-color;
        }
    }
}

.secondary-button {
    @extend %primary-font;
    @include siteButton($secondary-color, $white);
    font-size: 24px;
    line-height: 26px;
    padding: 16px 40px 13px 40px;
    text-transform: uppercase;
    &:hover {
        background-color: $button-link-hover;
        color: $white;
    }
    &__character {
        display: inline-block;
        text-align: center;
    }
}

.pink-button {
    @extend %primary-font;
    @include siteButton($pink, $white);
}

.white-button {
    @extend %secondary-font;
    @include siteButton($white, $secondary-color);
}

.button-asset--audio {
    @include siteButton($white, $black);
    &:hover {
        background-color: $white;
        color: $black;
    }
}

.back-button {
    margin: 20px 0 48px 35px;
    a {
        @extend %primary-font;
        text-transform: capitalize;
        position: relative;
        font-size: 20px;
        color: $pink;

        &::before {
            content: "<";
            position: absolute;
            display: block;
            top: 50%;
            left: -25px;
            height: 23px;
            width: 23px;
            transform: translateY(-50%);
            top: 5px;
            @media (max-width: $s) {
                height: 18px;
                width: 18px;
                top: 3px;
            }
        }
    }
}
