.footer {
    &__nav {
        margin-bottom: 1em;
    }
    &-container {
        max-width: $m;
        margin: 0 auto;

        h2 {
            @extend %secondary-font;
            color: $pink;
            font-size: 46px;
            line-height: normal;
            margin-bottom: 0;
            @media (min-width: $s) {
                font-size: $h2-font-size;
            }
        }
    }
    * {
        @extend %open-sans;
        color: $dark-grey;
    }

    .social-medias {
        display: flex;
        justify-content: center;
        a {
            display: inline-block;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            flex-flow: column nowrap;
            justify-content: center;
            border-radius: 50%;
            background: $pink;
            margin: 0 10px;
            span {
                display: flex;
                align-items: center;
                &:before {
                    color: $white;
                    font-size: 26px;
                }
            }
        }
    }
}
