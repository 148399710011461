.main-navigation {
    background-color: $nav-background-colour;
    padding: 10px;
    @media (min-width: 768px) {
        padding: 0 8px;
    }
    &.show-menu {
        padding-bottom: 32px;
        @media (min-width: 768px) {
            padding: 0;
        }
    }
    .main-menu {
        @media (max-width: 767px) {
            margin-top: 8px;
        }
        li {
            margin: 0;
            a {
                height: 100%;
                display: block;
                padding: 16px;
                color: $nav-links-colour;
                @extend %primary-font;
                font-size: 35px;
                line-height: 45px;
                text-transform: capitalize;
                @media (min-width: $m) {
                    padding: 10px 32px;
                }
                &:hover {
                    background-color: $nav-links-background-colour-hover;
                    color: $nav-links-colour-hover;
                }
                &.is-active {
                    background-color: $nav-current-link-background-colour;
                    color: $nav-links-colour-hover;
                }
            }
        }
    }
    .hamburger-box {
        .hamburger-inner,
        ::after,
        ::before {
            background-color: $white !important;
        }
    }
}
